export const getUTCTimestampFromDateString = (dateString) => {
  const datePattern = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
  if (!datePattern.test(dateString)) {
    return null;
  }

  const [day, month, year] = dateString.split('.');
  const utcDate = new Date(Date.UTC(year, month - 1, day));
  return utcDate.getTime();
};

export const getUTCTimestampFromDate = (date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return null;
  }

  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return utcDate.getTime();
};

export const getDateMinusOneMonth = () => {
  const utc = Date.now();
  const d = new Date(utc);
  d.setMonth(d.getMonth() - 1);
  return d;
};
